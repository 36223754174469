<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="a" itemtype="" style="width: 100px">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge class="item">
              <span style="width: 100px">全部</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="未入库" name="b" itemtype="0">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status0" class="item">
              <span>未入库</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已入库" name="c">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status1" class="item">
              <span>已入库</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待审核" name="d">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status2" class="item">
              <span>待审核</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待打包" name="e">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status3" class="item">
              <span>待打包</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待发货" name="f">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status4" class="item">
              <span>待发货</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待支付" name="g">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status5" class="item">
              <span>待支付</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="转运中" name="h">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status6" class="item">
              <span>转运中</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已签收" name="i">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status7" class="item">
              <span>已签收</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="暂存" name="j">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status10" class="item">
              <span>暂存中</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已拒绝" name="k">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status8" class="item">
              <span>已拒绝</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="退款中" name="l">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status11" class="item">
              <span>退款中</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已退款" name="m">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status12" class="item">
              <span>已退款</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
    </el-tabs>
    <!-- 搜索部分 -->
    <div class="table-container">
      <vab-query-form style="margin: 0">
        <el-form ref="form" :model="queryForm" :inline="true" @submit.native.prevent>
          <el-form-item label="订单编号">
            <el-input v-model="queryForm.code" placeholder="请输入订单编号" clearable />
          </el-form-item>
          <el-form-item label="用户ID">
            <el-input v-model="queryForm.user_code" placeholder="请输入用户ID" clearable />
          </el-form-item>

          <el-form-item label="运单号">
            <el-input v-model="queryForm.express_code" placeholder="请输入运单号" clearable />
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" native-type="submit" @click="handleQuery">
              查询
            </el-button>
          </el-form-item>

          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
            添加包裹
          </el-button>
          <el-button icon="el-icon-delete" type="danger" @click="handleDelete">
            批量删除
          </el-button>
          <el-button type="primary" @click="exportForm()">批量导出</el-button>
          <el-button v-if="isShowDabao" type="primary" @click="setQuery(3)">
            批量审核通过
          </el-button>
          <el-button v-if="isShowFahuo" type="primary" @click="setQuery(4)">
            批量发货
          </el-button>
          <el-button v-if="isShowQianshou" type="primary" @click="setQuery(7)">
            批量签收
          </el-button>
          <el-button v-if="isShowDabao" type="primary" @click="setQuery(8)">
            批量审核拒绝
          </el-button>
        </el-form>
      </vab-query-form>

      <vab-query-form style="margin-bottom: 15px">
        <div style="float: left">
          <el-button :loading="doPrintLoading" type="primary" @click="doPrint('YT')">
            圆通面单打印
          </el-button>
          <el-button :loading="doPreviewLoading" type="primary" @click="doPreview('YT')">
            圆通打印预览
          </el-button>
          <el-button :loading="doPrintLoading" type="primary" @click="doPrint('SF')">
            顺丰面单打印
          </el-button>
          <el-button :loading="SFdoPreviewLoading" type="primary" @click="doPreview('SF')">
            顺丰打印预览
          </el-button>
          <el-button v-if="pullFormBtn" type="primary" @click="pullForm(1)">
            圆通批量拉单
          </el-button>
          <el-button v-if="pullFormBtn" type="primary" @click="pullForm(2)">
            京东批量拉单
          </el-button>
          <el-button v-if="pullFormBtn" type="primary" @click="pullForm(3)">
            顺丰批量拉单
          </el-button>
        </div>
      </vab-query-form>

      <el-table ref="tableSort" v-loading="listLoading" :data="list" :element-loading-text="elementLoadingText"
        :height="height" style="width: 100%" border @selection-change="setSelectRows" @sort-change="tableSortChange">
        <el-table-column show-overflow-tooltip type="selection" width="55" align="center"></el-table-column>
        <!-- 昵称 -->
        <el-table-column show-overflow-tooltip prop="user_name" label="昵称"></el-table-column>
        <!-- ID -->
        <el-table-column show-overflow-tooltip prop="user_code" label="ID" width="100"></el-table-column>
        <!-- 商品图片 -->
        <el-table-column show-overflow-tooltip label="商品图片">
          <template #default="{ row }">
            <el-image :preview-src-list="imageList" :src="row.pic"></el-image>
          </template>
        </el-table-column>
        <!-- 订单编号 -->
        <el-table-column show-overflow-tooltip prop="code" label="订单编号" width="350"></el-table-column>
        <!-- 运单号 -->
        <el-table-column show-overflow-tooltip prop="express_code" label="运单编号" width="200"></el-table-column>
        <!-- 包裹状态 -->
        <el-table-column label="状态" align="center">
          <template #default="{ row }">
            <el-tag v-if="row.status == 0" size="small" type="danger">
              未入库
            </el-tag>
            <el-tag v-if="row.status == 1" size="small" type="success">
              已入库
            </el-tag>
            <el-tag v-if="row.status == 2" size="small" type="primary">
              待审核
            </el-tag>
            <el-tag v-if="row.status == 3" size="small" class="dabaoStyle">
              待打包
            </el-tag>
            <el-tag v-if="row.status == 4" size="small" class="fahuoStyle">
              待发货
            </el-tag>
            <el-tag v-if="row.status == 5" size="small" type="warning">
              待支付
            </el-tag>
            <el-tag v-if="row.status == 6" size="small" class="zhuanyunStyle">
              转运中
            </el-tag>
            <el-tag v-if="row.status == 7" size="small" type="success">
              已签收
            </el-tag>
            <el-tag v-if="row.status == 8" size="small" type="danger">
              已拒绝
            </el-tag>
            <el-tag v-if="row.status == 10" size="small" type="info">
              暂存
            </el-tag>
            <el-tag v-if="row.status == 11" size="small" type="info">
              退款中
            </el-tag>
            <el-tag v-if="row.status == 12" size="small" type="info">
              已退款
            </el-tag>
          </template>
        </el-table-column>
        <!-- 订单金额 -->
        <el-table-column show-overflow-tooltip prop="price" label="订单金额"></el-table-column>
        <!-- 退款金额 -->
        <el-table-column align="center" show-overflow-tooltip prop="refund_price" label="退款金额">
          <template #default="{ row }">
            <span v-if="row.status == 12">{{ row.refund_price }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <!-- 订单重量 -->
        <el-table-column show-overflow-tooltip prop="weight" label="订单重量"></el-table-column>
        <!-- 打印状态 -->
        <el-table-column show-overflow-tooltip prop="print" label="打印状态" align="center">
          <template #default="{ row }">
            <el-tag v-if="row.print == 0" size="mini" type="danger">
              未打印
            </el-tag>
            <el-tag v-if="row.print == 1" size="mini" type="success">
              已打印
            </el-tag>
          </template>
        </el-table-column>

        <!-- 订单发货方式 -->
        <el-table-column show-overflow-tooltip prop="shipping_type_name" label="发货方式" align="center"></el-table-column>
        <!-- 收件人 -->
        <el-table-column show-overflow-tooltip prop="name" label="收件人" align="center"></el-table-column>
        <!-- 手机号 -->
        <el-table-column show-overflow-tooltip prop="phone" label="手机号" width="110" align="center"></el-table-column>

        <el-table-column show-overflow-tooltip label="创建时间" prop="add_time" width="170" sortable
          align="center"></el-table-column>
        <el-table-column show-overflow-tooltip label="操作面板" width="250px" fixed="right">
          <template #default="{ row }">
            <el-button size="mini" @click="handleEdit(row)">编辑</el-button>
            <el-button type="primary" size="mini" @click="handledetails(row)">
              详情
            </el-button>
            <el-button type="danger" size="mini" @click="handleDelete(row)">
              删除
            </el-button>
            <el-button v-if="row.status == 11" type="warning" size="mini" @click="refund(row)">
              退款
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :background="background" :current-page="queryForm.page" :layout="layout"
        :page-sizes="[20, 40, 60, 100]" :page-size="queryForm.limit" :total="total"
        @current-change="handleCurrentChange" @size-change="handleSizeChange"></el-pagination>
      <manage-parcel ref="edit" @fetch-data="getnewlist"></manage-parcel>
      <parcel-details ref="edit1" @fetch-data="getnewlist"></parcel-details>
      <tui-kuan ref="tuikuan" @change="getnewlist"></tui-kuan>
    </div>
  </div>
</template>

<script>
  import {
    orderList,
    goodsDel,
    orderStatus,
    orderNotice,
    oexcelOut,
    pullingCheck,
    orderDel,
    orderRefundRefund,
  } from '@/api/xgcApi'
  import {
    Order_printStatus,
    Order_printCheck
  } from '@/api/print'
  import manageParcel from '../components/manageParcel'
  import parcelDetails from '../components/parcelDetails'
  import tuiKuan from '../components/tuikuan.vue'
  import {
    baseURL_print,
    getUploadUrl
  } from '@/config'
  import {
    number
  } from 'echarts'
  export default {
    name: 'ComprehensiveTable',
    components: {
      manageParcel,
      parcelDetails,
      tuiKuan,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height: '65vh', //表单高度
        isShowFahuo: false, //发货按钮
        isShowQianshou: false, //签收按钮
        isShowDabao: false, //打包按钮
        pullFormBtn: false, //拉单按钮
        doPrintLoading: false, //打印面单loading
        doPreviewLoading: false, //打印预览loading
        SFdoPreviewLoading: false, //顺丰打印预览loading
        printTaskId: 0, //打印相关
        printers: null, //打印相关
        chooseItem: [], //选中的数据
        defaultPrinter: null, //打印相关
        waybillNO: '0000000000000', //打印相关
        waybillTemplateURL: baseURL_print + 'admin/index/print', //打印请求路径
        socket: null, //打印相关
        list: [], //form的list
        imageList: [], //图片数组
        listLoading: true, //页面加载loading
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0, //数据条数
        background: true,
        selectRows: '', //多选
        elementLoadingText: '正在加载...', //页面加载文字展示
        queryForm: {
          page: 1,
          limit: 20,
          status: '',
          type: 1,
        }, //请求的数据
        activeName: 'a', //表单切换初始化，显示全部
        remindCount: {}, //提示小红点数据
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    mounted() {
      this.doConnect()
    },
    methods: {
      // table，切换方法
      handleClick(tab, event) {
        switch (tab.name) {
          case 'a':
            this.queryForm.status = ''
            this.pullFormBtn =
              this.isShowDabao =
              this.isShowFahuo =
              this.isShowQianshou =
              false
            break
          case 'b':
            this.queryForm.status = 0
            this.pullFormBtn = false
            this.isShowDabao = this.isShowQianshou = this.isShowFahuo = false
            break
          case 'c':
            this.queryForm.status = 1
            this.pullFormBtn = false
            this.isShowQianshou = true
            this.isShowDabao = this.isShowFahuo = false
            break
          case 'd':
            this.queryForm.status = 2
            this.pullFormBtn = this.isShowDabao = true
            this.isShowQianshou = this.isShowFahuo = false
            break
          case 'e':
            this.queryForm.status = 3
            this.pullFormBtn = this.isShowFahuo = true
            this.isShowDabao = this.isShowQianshou = false
            break
          case 'f':
            this.queryForm.status = 4
            this.pullFormBtn = true
            this.isShowDabao = this.isShowQianshou = this.isShowFahuo = false
            break
          case 'g':
            this.queryForm.status = 5
            this.pullFormBtn = true
            this.isShowDabao = this.isShowFahuo = this.isShowQianshou = false
            break
          case 'h':
            this.queryForm.status = 6
            this.pullFormBtn = this.isShowQianshou = true
            this.isShowDabao = this.isShowFahuo = false
            break
          case 'i':
            this.queryForm.status = 7
            this.pullFormBtn = true
            this.isShowDabao = this.isShowFahuo = this.isShowQianshou = false
            break
          case 'j':
            this.queryForm.status = 10
            this.pullFormBtn = true
            this.isShowDabao = this.isShowFahuo = this.isShowQianshou = false
            break
          case 'k':
            this.queryForm.status = 8
            this.isShowDabao = true
            this.isShowFahuo = this.isShowQianshou = this.pullFormBtn = false
            break
          case 'l':
            this.queryForm.status = 11
            this.isShowDabao =
              this.isShowFahuo =
              this.isShowQianshou =
              this.pullFormBtn =
              false
            break
          case 'm':
            this.queryForm.status = 12
            this.isShowDabao =
              this.isShowFahuo =
              this.isShowQianshou =
              this.pullFormBtn =
              false
            break
        }
        this.queryForm.page = 1
        this.fetchData()
      },
      // 请求列表数据
      getnewlist() {
        this.fetchData()
      },
      // 列表图片查看
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      // 多选
      setSelectRows(val) {
        this.selectRows = val
        this.chooseItem = val
      },
      // 添加，country=1韩国包裹添加
      handleAdd() {
        this.$refs['edit'].showEdit({
          country: 1,
        })
      },
      // 点击修改编辑
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },

      // 点击详情
      handledetails(row) {
        this.$refs['edit1'].showEdit(row)
      },

      // 点击退款
      refund(row) {
        // this.$prompt的message前面加必填标志 *
        this.$refs['tuikuan'].open(row, 1)
        // const h = this.$createElement
        // this.$prompt(
        //     h('p', null, [
        //       h('i', {
        //         style: 'color: red'
        //       }, '* '),
        //       h('span', null, '请输入申请理由：'),
        //     ]),
        //     '提示', {
        //       confirmButtonText: '确定',
        //       cancelButtonText: '取消',
        //       inputValue: '',
        //       inputPattern: /^.+$/,
        //       inputErrorMessage: '请输入内容',
        //     }
        //   )
        //   .then(({
        //     value
        //   }) => {
        //     var date = {
        //       id: row.id,
        //       price: row.price,
        //       type: 1, //1正常包裹 2补拍 3代买 4代发 5代买商品
        //       refund_remarks: value, //退款理由
        //     }
        //     orderRefundRefund(date).then((res) => {
        //       if (res.code == 200) {
        //         this.fetchData()
        //         this.$baseMessage(res.msg, 'success')
        //       } else {
        //         this.$baseMessage(res.msg, 'error')
        //       }
        //     })
        //   })
        //   .catch((err) => {})
      },

      // 批量导出
      exportForm() {
        if (this.selectRows.length > 0) {
          this.$baseConfirm('确定导出已选', null, async () => {
            const ids = this.selectRows.map((item) => item.id).join()
            const {
              data
            } = await oexcelOut({
              id: ids,
            })
            const fileUrl = getUploadUrl + data
            window.open(fileUrl)
            this.$message({
              type: 'success',
              message: '导出成功',
            })
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      // 删除
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const {
              msg
            } = await orderDel({
              id: row.id,
            })
            this.$baseMessage(msg, 'success')
            this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const {
                msg
              } = await orderDel({
                id: ids,
              })
              this.$baseMessage(msg, 'success')
              this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error')
            return false
          }
        }
      },

      // 批量打包。批量发货、批量签收、批量审核拒绝
      setQuery(e) {
        if (this.selectRows.length > 0) {
          if (e == 3) {
            this.$baseConfirm('确定通过已选', null, async () => {
              const ids = this.selectRows.map((item) => item.id).join()
              const {
                msg
              } = await orderStatus({
                order_id: ids,
                status: e,
              })
              this.$message({
                type: 'success',
                message: '审核成功',
              })
              this.fetchData()
            })
          }
          if (e == 4) {
            this.$baseConfirm('确定发货已选', null, async () => {
              const ids = this.selectRows.map((item) => item.id).join()
              const {
                msg
              } = await orderStatus({
                order_id: ids,
                status: e,
              })
              this.$message({
                type: 'success',
                message: '发货成功',
              })
              this.fetchData()
            })
          }
          if (e == 7) {
            this.$baseConfirm('确定签收已选', null, async () => {
              const ids = this.selectRows.map((item) => item.id).join()
              const {
                msg
              } = await orderStatus({
                order_id: ids,
                status: e,
              })
              this.$message({
                type: 'success',
                message: '签收成功',
              })
              this.fetchData()
            })
          }
          if (e == 8) {
            this.$baseConfirm('确定拒绝已选', null, async () => {
              const ids = this.selectRows.map((item) => item.id).join()
              const {
                msg
              } = await orderStatus({
                order_id: ids,
                status: e,
              })
              this.$message({
                type: 'success',
                message: '操作成功',
              })
              this.fetchData()
            })
          }
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      // 批量拉单
      pullForm(e) {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join()
          this.$baseConfirm('你确定要拉取选中项吗', null, async () => {
            const {
              data,
              code
            } = await pullingCheck({
              id: ids,
              type: e,
            })
            if (code == 200) {
              const {
                msg
              } = await orderNotice({
                id: ids,
                type: e,
              })

              this.$baseMessage(msg, 'success')
            } else {
              this.$baseMessage(msg, 'error')
            }
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      // 分页
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      // 分页
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      // 查询
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      // 请求列表数据，type=1，韩国包裹
      async fetchData() {
        this.listLoading = true
        // this.doConnect()
        const {
          data
        } = await orderList(this.queryForm)
        this.list = data.list
        this.remindCount = data.statusCount
        this.total = data.count
        const imageList = []
        data.list.forEach((item, index) => {
          imageList.push(item.pic)
        })

        this.imageList = imageList

        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },

      /**
       * @name wqz
       * @function 面单打印相关方法
       * @time 2024年4月18日09:32:42
       */
      doConnect() {
        var that = this
        this.socket = new WebSocket('ws://127.0.0.1:13528')
        var result = '打印成功'
        var tmptaskID = '0'
        var that = this
        // 打开Socket
        that.socket.onopen = function(event) {
          // 监听消息
          that.socket.onmessage = function(event) {
            var data = JSON.parse(event.data)
            that.printers = data.printers
            that.defaultPrinter = data.defaultPrinter
            if (data.cmd == 'notifyPrintResult') {
              // 打印出现问题
              var result = '打印成功'
              if (data.taskID != tmptaskID) {
                for (var i = 0; i < data.printStatus.length; i++) {
                  if (data.printStatus[i].status == 'failed') {
                    result = '打印失败'
                    that.$message({
                      type: 'error',
                      message: '打印失败',
                    })
                    break
                  } else if (data.printStatus[i].status == 'canceled') {
                    that.$message({
                      type: 'error',
                      message: '取消打印任务',
                    })
                    result = '取消打印任务'
                    break
                  }
                }
                tmptaskID = data.taskID
              }
              that.doPreviewLoading = false
              that.doPrintLoading = false
              that.SFdoPreviewLoading = false
            } else if (data.cmd == 'print') {
              // 这里面写打印/预览成功之后的回调
              that.doPreviewLoading = false
              that.doPrintLoading = false
              that.SFdoPreviewLoading = false
              if (data.previewURL) {
                // 预览成功打开pdf文件url
                window.open(data.previewURL)
              } else {
                // 打印成功
                // var date =this.chooseItem
                var ids = []
                that.chooseItem.forEach((item, index) => {
                  ids.push(item.id)
                })
                var date = {
                  id: ids.join(','),
                }
                if (data.status != 'success') {
                  that.$message({
                    type: 'error',
                    message: data.msg,
                  })
                } else {
                  Order_printStatus(date).then((res) => {
                    // 这个是打印成功回调
                    that.$message({
                      type: 'success',
                      message: '打印成功',
                    })
                  })
                }
              }
            }
          }
          // 监听Socket的关闭
          that.socket.onclose = function(event) {
            that.$message({
              type: 'error',
              message: '打印机失去联系，请刷新网页重新连接',
            })
          }
        }
        that.socket.onerror = function(event) {
          that.$message({
            type: 'error',
            message: '打印机出现问题,请联系客服',
          })
        }
      },

      // 打印预览
      doPreview(type) {
        if (this.selectRows.length > 0) {
          if (type == 'YT') {
            // 圓通面單打印
            // var ids = this.selectRows.map((item) => item.id).join(',')
            var ids = []
            this.selectRows.forEach((item) => {
              if (item.pulling_type == 1) {
                ids.push(item.id)
              }
            })
            this.$baseConfirm('你确定打印预览选中项吗', null, async () => {
              var data = {
                id: ids,
              }
              Order_printCheck(data).then((res) => {
                var printData = this.getPrintData()
                printData.task.preview = true
                this.doPreviewLoading = true
                this.socket.send(JSON.stringify(printData))
              })
            })
          }
          if (type == 'SF') {
            // 顺丰面单打印
            var ids = []
            this.selectRows.forEach((item) => {
              if (item.pulling_type == 3) {
                ids.push(item.id)
              }
            })
            var data = {
              id: ids,
            }
            // 验证接口
            Order_printCheck(data).then((res) => {
              var arrs = []
              var from = {}
              from = res.data.from
              res.data.list.forEach((item, index) => {
                var dataArr = {
                  documentID: this.waybillNO,
                  contents: [{
                    templateURL: 'https://cloudprint.cainiao.com/template/standard/771230/12',
                    signature: "MD:kw4V6Gtj/7ZasLyotby+HA==",
                    data: {
                      "orderNo": item.code, //订单号
                      "logisticsNo": item.express_code, //运单号
                      "shortAddress": item.pulling.mark, // 三段码
                      "packageCenterName": item.packageCenterName, //
                      "consignee": item.name, //收件人
                      "consigneeTel": item.phone, //收件人联系方式
                      "consigneeAddr": `${item.province_name} ${item.city_name} ${item.area_name} ${item.address}`, //收件人地址
                      "consigner": from.username, //发件人
                      "consignerTel": from.mobile, //发件人电话
                      "consignerAddr": `${from.province} ${from.city} ${from.area} ${from.address}`, //发件人地址
                      "goodsInfo": item.brand.brand_name?item.brand.brand_name:'', //物品名
                      "note": item.remark2, //备注
                      "logisticsMark": '', // 没用
                      "twoDimensionCode": item.pulling.two_dimension_code, //二维码
                      "now": item.add_time //时间
                    }
                  }]
                }
                arrs.push(dataArr)
              })
              var printData = this.SFgetPrintData(arrs)
              printData.task.preview = true
              this.SFdoPreviewLoading = true
              this.socket.send(JSON.stringify(printData))
            })

          }

        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      //打印电子面单
      doPrint(type) {
        if (this.selectRows.length > 0) {
          // const ids = this.selectRows.map((item) => item.id).join()
          // this.$baseConfirm('你确定打印选中项吗', null, async () => {
          //   var data = {
          //     id: ids,
          //   }
          //   Order_printCheck(data).then((res) => {
          //     var printData = this.getPrintData()
          //     this.doPrintLoading = true
          //     this.socket.send(JSON.stringify(printData))
          //   })
          // })
          if (type == 'YT') {
            // 圓通面單打印
            // var ids = this.selectRows.map((item) => item.id).join(',')
            var ids = []
            this.selectRows.forEach((item) => {
              if (item.pulling_type == 1) {
                ids.push(item.id)
              }
            })
            this.$baseConfirm('你确定打印预览选中项吗', null, async () => {
              var data = {
                id: ids,
              }
              Order_printCheck(data).then((res) => {
                var printData = this.getPrintData()
                this.doPreviewLoading = true
                this.socket.send(JSON.stringify(printData))
              })
            })
          }
          if (type == 'SF') {
            // 顺丰面单打印
            var ids = []
            this.selectRows.forEach((item) => {
              if (item.pulling_type == 3) {
                ids.push(item.id)
              }
            })
            var data = {
              id: ids,
            }
            // 验证接口
            Order_printCheck(data).then((res) => {
              var arrs = []
              var from = {}
              from = res.data.from
              res.data.list.forEach((item, index) => {
                var dataArr = {
                  documentID: this.waybillNO,
                  contents: [{
                    templateURL: 'https://cloudprint.cainiao.com/template/standard/771230/12',
                    signature: "MD:kw4V6Gtj/7ZasLyotby+HA==",
                    data: {
                      "orderNo": item.code, //订单号
                      "logisticsNo": item.express_code, //运单号
                      "shortAddress": item.pulling.mark, // 三段码
                      "packageCenterName": item.packageCenterName, //
                      "consignee": item.name, //收件人
                      "consigneeTel": item.phone, //收件人联系方式
                      "consigneeAddr": `${item.province_name} ${item.city_name} ${item.area_name} ${item.address}`, //收件人地址
                      "consigner": from.username, //发件人
                      "consignerTel": from.mobile, //发件人电话
                      "consignerAddr": `${from.province} ${from.city} ${from.area} ${from.address}`, //发件人地址
                      "goodsInfo": item.brand.brand_name?item.brand.brand_name:'', //物品名
                      "note": item.remark2, //备注
                      "logisticsMark": '', // 没用
                      "twoDimensionCode": item.pulling.two_dimension_code, //二维码
                      "now": item.add_time //时间
                    }
                  }]
                }
                arrs.push(dataArr)
              })
              var printData = this.SFgetPrintData(arrs)
              this.SFdoPreviewLoading = true
              this.socket.send(JSON.stringify(printData))
            })

          }
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      // 组装打印机需要的参数
      getPrintData() {
        var dataArr = []
        this.chooseItem.forEach((item, ind) => {
          if(item.pulling_type==1){
            dataArr.push({
              documentID: this.waybillNO,
              contents: [
                //电子面单部分
                {
                  templateURL: this.waybillTemplateURL + '?id=' + item.id,
                  signature: 'MD:kw4V6Gtj/7ZasLyotby+HA==',
                  //电子面单数据
                },
              ],
            })
          }
        })
        if(dataArr.length<=0){
          this.$message({
            type:'warning',
            message:"没有可打印的圆通面单"
          })
          return
        }
        this.printTaskId = parseInt(1000 * Math.random())
        var request = {
          cmd: 'print',
          requestID: '12345678901234567890',
          version: '1.0',
          task: {
            taskID: '' + this.printTaskId,
            preview: false,
            printer: this.defaultPrinter,
            notifyMode: 'allInOne',
            previewType: 'pdf',
            documents: dataArr,
          },
        }
        return request
      },
      SFgetPrintData(arrs) {
        var dataArr = []
        if(arrs.length<=0){
          this.$message({
            type:'warning',
            message:"没有可打印的顺丰面单"
          })
          return
        }
        this.printTaskId = parseInt(1000 * Math.random())
        var request = {
          cmd: 'print',
          requestID: '12345678901234567890',
          version: '1.0',
          task: {
            taskID: '' + this.printTaskId,
            preview: false,
            printer: this.defaultPrinter,
            notifyMode: 'allInOne',
            previewType: 'pdf',
            documents: arrs,
          },
        }
        return request
      }
    },
  }
</script>

<style scoped>
  :deep .el-button--mini {
    padding: 5px 11px;
  }

  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .demo-tabs .custom-tabs-label span {
    vertical-align: super;
    margin-left: 4px;
  }

  ::v-deep(.el-badge__content.is-fixed) {
    top: 11px;
    right: 17px;
  }

  .table-container {
    padding: 15px;
    padding-top: 30px;
    box-sizing: border-box;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }

  .el-tabs__item {
    width: 200px;
    /* 设置宽度为200px */
  }

  .el-badge {
    width: 60px !important;
  }

  ::v-deep(.el-tabs__header) {
    margin: 0 !important;
  }

  ::v-deep(.el-tabs__header span) {
    font-weight: bold;
  }

  .demo-table-expand {
    font-size: 0;
    padding-left: 150px;
    box-sizing: border-box;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 30%;
  }

  .dabaoStyle {
    background: #ffddaa;
    color: #999;
    border: 1px solid #fd5;
  }

  .fahuoStyle {
    background: #99dddd;
    color: #999;
    border: 1px solid 00FFFF;
  }

  .zhuanyunStyle {
    background: #ffffaa;
    color: #999;
    border: 1px solid FFFF00;
  }
</style>
